<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();
const { isCanPlay } = useBingoData({ immediate: false });
const prepareImgUrl = useImage();

const bgImg = `url(${prepareImgUrl("/nuxt-img/bingo/benefits/banner-bg.webp", {
	loading: "lazy",
	width: 992,
	height: 442
})})`;

const handleBingo = () => {
	loginGuard({
		success: () => {
			if (isCanPlay.value) {
				open("LazyOModalBingoPlay");
				return;
			}
			open("LazyOModalRacesGames");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<div class="banner-bingo">
		<div class="head">
			<AText :size="14">
				{{ t("bingo.benefit.title") }}
			</AText>
			<AText :size="24" :modifiers="['semibold']">
				{{ t("bingo.benefit.subtitle") }}
			</AText>
		</div>
		<AButton variant="primary" size="lg" :modifiers="['wide']" @click="handleBingo">
			<AText :size="16" :modifiers="['capitalize']">
				{{ t("bingo.benefit.btn.name") }}
			</AText>
		</AButton>
	</div>
</template>

<style lang="scss" scoped>
.banner-bingo {
	width: 100%;
	background: v-bind(bgImg) no-repeat center center / cover;
	height: 50%;
	border-radius: 24px;
	border: 2px solid var(--cordoba);
	display: flex;
	gap: 24px;
	flex-direction: column;
	justify-content: space-between;
	padding: 24px;
	overflow: hidden;

	.head {
		display: flex;
		max-width: 320px;
		gap: 4px;
		flex-direction: column;
	}

	button {
		max-width: 165px;
	}
}
</style>
